html {
  scroll-behavior: smooth;
}

#product-hunt {
  margin-top: 1rem;
}

#link-snippet-headline {
  margin: 1rem 2.5rem 1rem 0;
}

/* Make the feedback Emoji appear next to the button */
#copy-button {
  position: relative;
  margin-right: 2px;
  overflow: visible;
}

#copy-button-feedback {
  position: absolute;
  left: -2rem;
  display: inline-block;
  transform: scale(1.3);
}

#link-snippet-container {
  overflow: hidden;
  display: grid;
  display: -ms-grid;
  -ms-grid-columns: 1fr;
}

#link-snippet-container > pre {
  overflow: auto;
  grid-column: 1;
  grid-row: 1;
  transition: transform 220ms cubic-bezier(0.175, 0.885, 0.32, 1) 220ms;
}

#link-snippet-container > pre[hidden] {
  display: block;
  visibility: hidden;
  transform: scale(0);
  transition: transform 220ms cubic-bezier(0.175, 0.885, 0.32, 1), visibility 0ms 220ms;
}

#theme-form > label:not(:last-of-type) {
  margin-right: 1rem;
}

#version-info th {
  width: 35%;
}

body > footer {
  text-align: right;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
